import { store } from "@/store/store";
import { grupo_dashboard } from "@/shared/dtos/grupo_dashboard";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";

@Module({
  namespaced: true,
  name: "grupo_dashboardModule",
  store,
  dynamic: true,
})
class grupo_dashboardModule extends VuexModule {
  public grupo_dashboards: grupo_dashboard[] = [];
  public grupo_dashboard: grupo_dashboard = new grupo_dashboard();

  @Action({ commit: "onGetgrupo_dashboards" })
  public async getgrupo_dashboards() {
    return await ssmHttpService.get(API.grupo_dashboard);
  }
  @Action({ commit: "onGetgrupo_dashboards" })
  public async getgrupo_dashboards_public() {
    return await ssmHttpService.get(API.public);
  }

  @Action({ commit: "onGetgrupo_dashboard" })
  public async getgrupo_dashboard(id: any) {
    return await ssmHttpService.get(API.grupo_dashboard + "/" + id);
  }

  @Action
  public async guardargrupo_dashboard(grupo_dashboard: grupo_dashboard) {
    return await ssmHttpService.post(
      API.grupo_dashboard,
      grupo_dashboard.toJson()
    );
  }

  @Action
  public async modificargrupo_dashboard(grupo_dashboard: grupo_dashboard) {
    return await ssmHttpService.put(
      API.grupo_dashboard + "/" + grupo_dashboard.id,
      grupo_dashboard
    );
  }

  @Action
  public async eliminargrupo_dashboard(grupo_dashboard: grupo_dashboard) {
    return await ssmHttpService.delete(
      API.grupo_dashboard + "/" + grupo_dashboard.id,
      null,
      false
    );
  }

  @Mutation
  public onGetgrupo_dashboards(res: grupo_dashboard[]) {
    this.grupo_dashboards = res ? res.map((x) => new grupo_dashboard(x)) : [];
  }

  @Mutation
  public onGetgrupo_dashboard(res: grupo_dashboard) {
    this.grupo_dashboard = new grupo_dashboard(res);
  }
}
export default getModule(grupo_dashboardModule);
