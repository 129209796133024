import { store } from "@/store/store";
import { sensor } from "@/shared/dtos/sensor";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";

@Module({
  namespaced: true,
  name: "sensorModule",
  store,
  dynamic: true,
})
class sensorModule extends VuexModule {
  public sensores: sensor[] = [];
  public sensor: sensor = new sensor();

  @Action({ commit: "onGetsensores" })
  public async getsensores() {
    return await ssmHttpService.get(API.sensor);
  }

  @Action({ commit: "onGetsensor" })
  public async getsensor(id: any) {
    return await ssmHttpService.get(API.sensor + "/" + id);
  }

  @Action({ commit: "onGetsensores" })
  public async getsensores_by_dispositivo(id: any) {
    return await ssmHttpService.get(API.sensor + "/dispositivo/" + id);
  }

  @Action
  public async guardarsensor(sensor: sensor) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    await ssmHttpService.post(API.sensor, sensor.toJson());
    this.getsensores();
  }

  @Action
  public async modificarsensor(sensor: sensor) {
    await ssmHttpService.put(API.sensor + "/" + sensor.id, sensor);
    this.getsensores();
  }

  @Action
  public async eliminarsensor(sensor: sensor) {
    await ssmHttpService.delete(API.sensor + "/" + sensor.id, null, false);
    this.getsensores();
  }

  @Mutation
  public onGetsensores(res: sensor[]) {
    this.sensores = res ? res.map((x) => new sensor(x)) : [];
  }

  @Mutation
  public onGetsensor(res: sensor) {
    this.sensor = new sensor(res);
  }
}
export default getModule(sensorModule);
