




















































































































import { dashboard } from "@/shared/dtos/dashboard";
import dashboardModule from "@/store/modules/dashboard-module";
import sensorModule from "@/store/modules/sensor-module";
import dispositivoModule from "@/store/modules/dispositivo-module";
import recurso_dataModule from "@/store/modules/recurso_data-module";
import grupo_dashboardMmodule from "@/store/modules/grupo_dashboard-module";
import { Component, Vue } from "vue-property-decorator";
import { RouterNames } from "@/router/routernames";
import { dispositivo_dashboard } from "@/shared/dtos/dispositivo_dashboard";
import { recurso_dashboard } from "@/shared/dtos/recurso_dashboard";
import { recurso_data } from "@/shared/dtos/recurso_data";
@Component({
  components: {
    MaterialCard: () => import("@/components/Cards/MaterialCard.vue"),
    Xbtn: () => import("@/components/buttons/x-btn.vue"),
    ConfigDashboardLimits: () =>
      import("@/views/config_plataforma/config_dashboards_limits.vue"),
  },
  $_veeValidate: { validator: "new" },
})
export default class DashboardsFormulario extends Vue {
  public visible_autocompletes = false;
  public frist = true;
  created() {
    if (this.is_new()) {
      dashboardModule.onGetdashboard(new dashboard());
    } else {
      dashboardModule.getdashboard(this.$route.params.id);
      this.visible_autocompletes = true;
    }
    sensorModule.getsensores();
    dispositivoModule.getdispositivos();
    recurso_dataModule.getrecurso_datas();
    grupo_dashboardMmodule.getgrupo_dashboards();
  }
  get grupos() {
    return grupo_dashboardMmodule.grupo_dashboards;
  }
  public get datasource() {
    return dashboardModule.dashboard;
  }

  public get sensores() {
    return sensorModule.sensores;
  }

  public get dispositivos() {
    return dashboardModule.dispositivos;
    // return dispositivoModule.dispositivos;
  }

  public get recurso_data() {
    if (dashboardModule.recursos.length > 0 && this.frist) {
      this.frist = false;
      for (let i = 0; i < dashboardModule.recursos.length; i++) {
        dashboardModule.recursos[i].nombre =
          dashboardModule.recursos[i].nombre +
          " [" +
          dashboardModule.recursos[i].id +
          "]";
      }
    }
    return dashboardModule.recursos;
    //return recurso_dataModule.recurso_datas;
  }

  public change_sensor() {
    dashboardModule
      .getdashboard_form(this.datasource.id_sensor)
      .then(() => (this.visible_autocompletes = true));
    this.frist = true;
  }

  public aceptar() {
    var dispositivos: dispositivo_dashboard[] = [];
    var recursos: recurso_dashboard[] = [];

    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        if (this.is_new()) {
          try {
            for (let i = 0; i < this.datasource.dispositivos.length; i++) {
              dispositivos.push(
                new dispositivo_dashboard({
                  id_dashboard: 0,
                  id_dispositivo: this.datasource.dispositivos[i],
                  id: 0,
                })
              );
            }
            this.datasource.dispositivos = dispositivos;
          } catch (error) {}

          try {
            for (let i = 0; i < this.datasource.recursos.length; i++) {
              recursos.push(
                new recurso_dashboard({
                  id_dashboard: 0,
                  id_recurso_data: this.datasource.recursos[i],
                  id: 0,
                })
              );
            }
            this.datasource.recursos = recursos;
          } catch (error) {}
          dashboardModule
            .guardardashboard(this.datasource)
            .then(() => this.cancelar());
        } else {
          try {
            for (let i = 0; i < this.datasource.dispositivos.length; i++) {
              dispositivos.push(
                new dispositivo_dashboard({
                  id_dashboard: 0,
                  id_dispositivo: this.datasource.dispositivos[i].id,
                  id: 0,
                })
              );
            }
            this.datasource.dispositivos = dispositivos;
          } catch (error) {}

          try {
            for (let i = 0; i < this.datasource.recursos.length; i++) {
              recursos.push(
                new recurso_dashboard({
                  id_dashboard: 0,
                  id_recurso_data: this.datasource.recursos[i].id,
                  id: 0,
                })
              );
            }
            this.datasource.recursos = recursos;
          } catch (error) {}
          dashboardModule
            .modificardashboard(this.datasource)
            .then(() => this.cancelar());
        }
      }
    });
  }
  public cancelar() {
    this.$router.push({ name: RouterNames.dashboardslista });
  }

  public is_new() {
    return Number.parseInt(this.$route.params.id) === -1;
  }
}
