var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MaterialCard',{staticClass:"m-shadow mt-8 pa-1 m-rouded",attrs:{"color":"white"}},[_c('template',{slot:"heading"},[_c('Xbtn',{attrs:{"text":"","color":"green","permisos":['write_roles', 'update_roles']},on:{"click":function($event){return _vm.aceptar()}}},[_vm._v("Aceptar")]),_c('Xbtn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.cancelar()}}},[_vm._v("Cancelar")]),(!_vm.is_new())?_c('Xbtn',{attrs:{"text":"","color":"red","permiso":"delete_roles"},on:{"click":function($event){return _vm.eliminar()}}},[_vm._v("Eliminar")]):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"Nombre"},model:{value:(_vm.datasource.nombre),callback:function ($$v) {_vm.$set(_vm.datasource, "nombre", $$v)},expression:"datasource.nombre"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"Tipo de medición"},model:{value:(_vm.datasource.tipo_medicion),callback:function ($$v) {_vm.$set(_vm.datasource, "tipo_medicion", $$v)},expression:"datasource.tipo_medicion"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.sensores,"item-value":"id","item-text":"nombre","label":"Sensor"},on:{"change":function($event){return _vm.change_sensor()}},model:{value:(_vm.datasource.id_sensor),callback:function ($$v) {_vm.$set(_vm.datasource, "id_sensor", $$v)},expression:"datasource.id_sensor"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"items":[
          { nombre: 'Dato simple' },
          { nombre: 'Reloj' },
          { nombre: 'Lineal' } ],"item-value":"nombre","item-text":"nombre","label":"Tipo de grafica"},model:{value:(_vm.datasource.tipo),callback:function ($$v) {_vm.$set(_vm.datasource, "tipo", $$v)},expression:"datasource.tipo"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.grupos,"item-value":"id","item-text":"nombre","label":"Grupo"},model:{value:(_vm.datasource.id_grupo_dashboard),callback:function ($$v) {_vm.$set(_vm.datasource, "id_grupo_dashboard", $$v)},expression:"datasource.id_grupo_dashboard"}})],1),(
        _vm.datasource.tipo !== 'Lineal' &&
          _vm.datasource.tipo !== undefined &&
          _vm.datasource.tipo !== null &&
          _vm.datasource.tipo !== ''
      )?_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{attrs:{"label":"Visible Limites cuadro de mando"},model:{value:(_vm.datasource.visible_gauges),callback:function ($$v) {_vm.$set(_vm.datasource, "visible_gauges", $$v)},expression:"datasource.visible_gauges"}})],1):_vm._e(),(_vm.visible_autocompletes)?_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.dispositivos,"item-text":"nombre","item-value":"id","multiple":"","small-chips":"","label":"Dispositivos"},model:{value:(_vm.datasource.dispositivos),callback:function ($$v) {_vm.$set(_vm.datasource, "dispositivos", $$v)},expression:"datasource.dispositivos"}})],1):_vm._e(),(_vm.visible_autocompletes)?_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.recurso_data,"item-text":"nombre","item-value":"id","multiple":"","small-chips":"","label":"Recursos"},model:{value:(_vm.datasource.recursos),callback:function ($$v) {_vm.$set(_vm.datasource, "recursos", $$v)},expression:"datasource.recursos"}})],1):_vm._e(),(
        _vm.datasource.tipo !== 'Lineal' &&
          _vm.datasource.tipo !== undefined &&
          _vm.datasource.tipo !== null &&
          _vm.datasource.tipo !== ''
      )?_c('v-col',{attrs:{"cols":"12"}},[_c('ConfigDashboardLimits',{attrs:{"datasource":_vm.datasource.configuracion_dash},on:{"update:datasource":function($event){return _vm.$set(_vm.datasource, "configuracion_dash", $event)}}})],1):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }